import './pages.scss';

import { Fade } from 'react-reveal';

import VolunteerList from '../components/volunteerList';

const Volunteer = () => {
  return (
    <div className="container projects">
      <div className='volunteer first'>
        <div className='volunteerPageTitle'>Volunteering</div>
        <Fade up>
          <span className='projectSubtitle'> Through the Years </span>
        </Fade>

        <VolunteerList />
      </div>

    </div>
  )
}

export default Volunteer;