import Fade from 'react-reveal/Fade';

const BooksQuote = () => {
  return (
    <div className="books-quote-block">
      <div className="bq-col books col col-md-6">
        <Fade top>
          <div className="inner">
            <b>Crying in HMart</b> by Michelle Zauner<br/>
            <b>The Bell Jar</b> by Sylvia Plath<br/>
          </div>
        </Fade>
        <div className="line"></div>
        <Fade bottom>
          <div className="describer">
            Books I'm Reading
          </div>
        </Fade>
      </div>
      <div class="bq-col quote col col-md-6">
        <Fade top>
          <div className="inner">
            <b>"The happiness of your life depends on the quality of your thoughts."</b><br />
            - Marcus Aurelius
          </div>
        </Fade>
        <div className="line"></div>
        <Fade bottom>
          <div className="describer">
            Current Quote
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default BooksQuote;