import React, { useState , useEffect } from 'react';
import { useRef } from 'react';

import Fade from 'react-reveal/Fade';

import starburst from '../assets/images/starburst.png';

// storing locally bc im lazy
const personalInfo = [
  {
    id: 0,
    title: 'The Beginnings',
    contents: 'A New York Queens native with a love for adventure. Attended Brooklyn Technical High School and got her Bachelors in Computer Science from Marist College in 2019.'  
  },
  {
    id: 1,
    title: 'The Now',
    contents: 'Currently, I’m a SWE  with a flair for front-end. I have had the opportunity to work with multiple companies worldwide, like IBM, Disney and Amazon.'
  },
  {
    id: 2,
    title: 'The Extra',
    contents: 'In my spare time, I love to knit/crochet, thrift shop, illustrate, play tennis, and spend time with family and friends. '
  }
]

function Misc() {
  const [slide, setSlide] = useState(0);
  const [show, doShow] = useState({slider: false})
  const visRef = useRef(null);

  const changeSlide = ( current, val ) => {
    if(current == 0 && val == 'minus') {
      setSlide(2);
    } else if (current == 2 && val == 'plus') {
      setSlide(0);
    } else if (val == 'minus') {
      setSlide(slide - 1);
    } else if (val == 'plus') {
      setSlide(slide + 1);
    }
  }

  return(
    <div className="container misc">
      <Fade bottom>
      <div className="slider" ref={visRef}>
        <div className="title">
          <div className="mb-2">All About Me</div>
          <h1>{personalInfo[slide].title}</h1>
        </div>
        <div className="contents d-flex">
          <div className="counter col col-md-3">
            {slide + 1} / 3
            <div className="btn-group">
              <button onClick={() => changeSlide(slide, 'minus')}>&larr;</button>
              <button onClick={() => changeSlide(slide, 'plus')}>&rarr;</button>
            </div>
          </div>
          <div className="textarea col col-md-9">
            {personalInfo[slide].contents}
          </div>
          <img src={starburst} id="nyc"/>
        </div>
      </div>
      </Fade>
    </div>
  );
}

export default Misc;