import React from 'react';

import '../src/pages/pages.scss';
import './index.css';

import {
  BrowserRouter as Router,
  Link,
  Route,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

import Home from './pages/home.js';
import Experience from './pages/experience.js';
import Resume from './pages/resume.js';

import Layout from './components/Layout';
import Volunteer from './pages/volunteer';

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/experience', name: 'Experience', Component: Experience},
  { path: '/volunteer', name: 'Volunteer', Component: Volunteer },
  { path: '/resume', name: 'Resume', Component: Resume },
]

export default function App() {
  const location = useLocation;
  return (
    <Router>
    <>
      <Layout>
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
              <div className="page">
                <Component />
              </div>
          </Route>
        ))}
      </Layout>
    </>
    </Router>
  )
}
