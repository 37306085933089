import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import Hamburger from '../assets/icons/icons8-menu-100.png';
import Closer from '../assets/icons/icons8-close-window-100.png';

import './components.scss';

const pageNames = [
  {name: ''}, {name: 'experience'}, {name: 'volunteer'}, {name: 'resume'}
];

function Navigation( props ) {
  const [hamburger, setHamburger] = useState(false);
  const [medDisplay, setMedDisplay] = useState('');
  const [bold, setBold] = useState('');

  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    setBold(location.pathname.substr(1));
  });

  const hider = ( className ) => {
    setMedDisplay(className);
    setTimeout(() => {setMedDisplay('d-none')}, 500)
  }

  const pageSet = ( page ) => {
    setBold(page);
    setMedDisplay('d-none');
    if(page != 'home') { history.push('/' + page) }
    else { (history.push('/')) }

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200)
  }

  return (
    <div className="container navigation p-0"> 
      <div className="innards">
        <div className={`overlay ${medDisplay.includes('d-flex') ? 'd-block' : 'd-none'}`}></div>
        <div className="logo">
          <button className='logoButton' onClick={() => pageSet('home')}>
            Alexa <br/>
            Javellana
          </button>
        </div>

        <div className='hamburger'>
          <button 
            className={`hamburger-button ${medDisplay == 'd-flex fadedown' ? 'd-none' : ''}`}
            onClick={() => (medDisplay == '' || 'd-none') ? setMedDisplay('d-flex fadedown') : null}
          >
            <img src={Hamburger} />
          </button>
          <button 
            className={`closer-button ${medDisplay == 'd-flex fadedown' ? 'd-flex' : ''}`}
            onClick={() => hider('d-flex fadeup')}
          >
            <img src={Closer}/>
          </button>
        </div>

        <div className={`menu-items ${medDisplay}`}>
          <div className="menu-med">
            <ul>
              {pageNames.map(name => 
                <button 
                  className={`menu-button ${name.name == bold ? 'bold' : ''}`}
                  onClick={() => pageSet(name.name)}>
                  <li>
                    {name.name != '' ? name.name : 'home'}
                  </li>
                </button>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navigation;