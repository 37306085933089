import smiley from '../assets/images/smiley.png';

import ProjectList from '../components/projectList.js';

import './pages.scss';

const Experience = () => {
  return (
    <div className="container projects">
      <div className="first">
        <img src={smiley} id="smiley"/>
          <div className='experience projectTitle'>
            <span id="work-experience">
              My Work Experience
            </span>
          </div>
        <div className="currently">
          <div className="status d-flex">
            <p>Having <b>graduated in 2019 with a degree in Computer Science</b>, these past few years 
            have been filled with amazing opportunities to work with companies in 
            <span className='bold-role'> major cities like Tokyo, New York and Seattle</span>. 
            I am a <span className='bold-role'>Front End Engineer</span> by trade, and love to dabble in <b>web development, design and 
            online optimizations</b>. Take a look below for detailed descriptions on my current and previous work experiences.
            </p>
          </div>
        </div>
      </div>

      <div className='linebreak'></div>

      <div className="list">
        <div className="entries">
          <ProjectList />
        </div>
      </div>
    </div>
  )
}

export default Experience;