import Fade from 'react-reveal/Fade';

import './components.scss';

import entries from '../assets/data/projects.json';

const ProjectList = () => {
  return (
    <>
      {entries.items.map(entry => 
      <Fade bottom>
        <div className="new-entry">

          <div className="content col col-md-9">
            <div className="title">{entry.title} <br/> {entry.date}</div>

            <div className="inner">
              {entry.contents.map((innerContent, index) => (
                  <div className="innerContent pb-3">
                  {innerContent}
                  {(index == 0 && entry.notes.length > 0) ? 
                    <div className="keynotes">
                      Main Takeaways
                      <ul>{entry.notes.map((note) => <li>{note}</li>)}</ul>
                    </div> 
                    : ''}
                </div>
              ))}
            </div>
            <div className="skills d-flex flex-row">
              {entry.skills.map(skill => (
                <div className="skill m-2">
                  {skill}
                </div>  
              ))}
            </div>
          </div>
          
          <a className="entry-images col-md-3" href={entry.url} style={{backgroundImage: `url(${entry.image})`}} target='__blank'>
          </a>
        </div> 
      </Fade>
      )}
    </>
  )
}

export default ProjectList;