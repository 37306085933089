import Fade from 'react-reveal/Fade';

import './components.scss';

import cityasvolunteer from '../assets/images/cityasvolunteer.png';
import japanesevolunteer from '../assets/images/japanesevolunteer.png';

const VolunteerList = () => {
  return (
    <div className='volunteer-list'>
      <div className='d-flex flex-col flex-md-row blurb'>
        <section className='volunteer-image inaka col-md-4'>
          <img src={japanesevolunteer} id="inaka"/>
        </section>
        <Fade>
          <div className='d-flex col-md-8 eventextcontents'>
            <section className='even volunteer-title'>
              <h3>Relief Farmhand Volunteer for Farms and Fields Around Japan</h3>
            </section>
            <section className='volunteer-story'>
              When you find yourself wanting to give back to nature, I highly recommend volunteering at your local farms.
              During the pandemic I began embarking on a new hobby: volunteering for farms in the Japanese countryside (also known as the inaka). 
              It seems like hundreds of years ago, for weeks at a time I'd find myself in Saitama, harvesting the summer
              fig bounty, weeding miles of rice fields, or pruning chestnut trees on a terrace farm in Osaka, Japan. 
              I also was a volunteer for the Artisan's Farmer's Market in Saitama, Japan, when I wanted a closer escape without 
              straying too far away from Tokyo.
            </section>
            <div className='volunteer-ack'>
              <section>
                From July to September 2020
              </section>
              <section>
                Farm volunteering, community building, Saitama, Osaka, Japan
              </section>
            </div>
          </div>
        </Fade>
      </div>

      <div className='d-flex flex-col flex-md-row blurb'>
        <section className='volunteer-image webdev col-md-4' id='mobile-webdev'>
          <img src={cityasvolunteer} id="webdev" />
        </section>
        <div className='d-flex col-md-8 textcontents'>
          <section className='odd volunteer-title'>
            <h3>Non-profit Web Development Instructor for Communities in NYC</h3>
          </section>
          <section className='volunteer-story'>
            During the Spring of 2022, I worked part-time with a non-profit organization in NYC to teach web development 
            to youth in middle and high school across Brooklyn and Manhattan, both in person and virtually, using 
            Scratch, HTML/CSS and JavaScript. 
          </section>
          <div className='volunteer-ack'>
            <section>
              From March to June 2022
            </section>
            <section>
              Web development, Scratch, HTML/CSS, JavaScript
            </section>
          </div>
        </div>
        <section className='volunteer-image webdev col-md-4' id='desktop-webdev'>
          <img src={cityasvolunteer} id="webdev" />
        </section>
      </div>

      <div className='d-flex flex-col flex-md-row blurb'>
        <div className='d-flex col-md-11 eventextcontents'>
          <section className='even volunteer-title' style={{'maxWidth': 'unset'}}> 
            <h3>Web Development Volunteer for A Wellness Studio in Manhattan</h3>
          </section>
          <section className='volunteer-story'>
            Using Squarespace, Mindbodyclient and custom HTML/CSS, supported a non-profit wellness studio to improve their 
            website interaction for patrons and supporters to enjoy yoga and wellness at their physical space in the 
            Lower East Side of Manhattan.
          </section>
          <div className='volunteer-ack'>
            <section>
              From October to December 2019
            </section>
            <section>
              Wellness, Squarespace, Web design, third party integration
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VolunteerList;