import Navigation from '../components/navigation';
import Misc from '../components/misc';
import BooksQuote from '../components/booksQuote';

import smiley from '../assets/images/smiley.png';
import zen from '../assets/images/zen.png';
import handheart from '../assets/images/handheart.png';
import stars from '../assets/images/stars.png';

import { Fade } from 'react-reveal';

import { useLocation } from 'react-router';

const Home = ( props ) => {
  return (
    <div className="home container p-0"> 
      <div className="first">
        <div className="elements d-flex">
          <div className="intro col">
            <p>Hi! I'm Alexa, a <span className="bold-role">Front End Software Engineer</span>, 
              currently working for <span className='bold-role'>Amazon, Prime Video</span>. Previously at <span className='company'>Liferay (Tokyo)</span>, 
              <span className='company'> IBM</span>,
              <span className='company'> and Marvel Entertainment</span>.
            </p>
          </div>

          <div class="image-box">
            <div class="handheart"> <img src={handheart} id="handheart"/> </div>
            <div class="smiley"> <img src={smiley} id="smiley"/> </div>
            <div class="zen"> <img src={zen} id="zen"/> </div>
          </div>
        </div>
      </div>

      <div className="second">
        <Misc />
        <Fade in>
        <div className="stars">
          <img src={smiley} id="stars"/>
          <img src={handheart} id="sml-handheart" />
          <img src={smiley} id="stars"/>
        </div>
        </Fade>
      </div>

      <div className="third">
        <BooksQuote />
      </div>
    </div>
  )
}

export default Home;