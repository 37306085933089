import React from 'react';
import Navigation from './navigation';
 
import Fade from 'react-reveal';

import github from '../assets/icons/icons8-github-60.png';
import linkedin from '../assets/icons/icons8-linkedin-60.png';
import email from '../assets/icons/icons8-envelope-dots-60.png'

const Layout =( {children} ) =>{
    return(
        <>
          <div>
            <Navigation />
          </div>
          <main className='container'>
            {children}
          </main>
          <Fade bottom>
          <footer className="footer">
            <div className="footer-content d-flex flex-row justify-content-center align-items-center h-100">
              <a href='https://github.com/AlexaDoesStuff'><img src={github} className='m-2 icon'></img></a>
              <a href='https://www.linkedin.com/in/alexa-j-2590ba96/'><img src={linkedin} className='m-2 icon'></img></a>
              <a href='mailto:ajavellana123@gmail.com'><img src={email} className='m-2 icon'></img></a>
            </div>
          </footer>
          </Fade>
        </>
    )
}

export default Layout;