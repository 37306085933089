import '../components/components.scss';

import handheart from '../assets/images/handheart.png';

import Fade from 'react-reveal/Fade';

const Resume = () => {
  return (
    <div className="resume container">
      <div className='download mt-3'>
        <a href={'/AlexaJFE.pdf'}>Download my resume!</a>
      </div>
      <div className="m-2">
        <div className='d-flex mt-3 top block'>
          <div className='col col-md-6 mb-3'>
            <h1>Academics</h1>
            <div className='line' />
              <Fade bottom>
                <div className="section mt-3">
                  <span className="bold-company">Marist College</span> <br/>
                  2015 - 2019<br/>
                  Bachelor of Science in <span className="bold">Computer Science</span> <br/>
                  Concentration in <span className="bold">Software Development</span> <br/>
                </div>
                <div className="section mt-3">
                  <span className="bold-company">Temple University Japan</span> <br/>
                  Fall Study Abroad 2017<br/>
                </div>
                <div className="section mt-3">
                  <span className="bold-company">Brooklyn Technical High School</span> <br/>
                  2011 - 2015<br/>
                </div>
              </Fade>
          </div>
          <div className='col col-md-6'>
            <h1>Work and Internships</h1>
            <div className='line' />
              <Fade bottom>
                <div className="section mt-3">
                  <div className="d-flex header"><span className="bold-company">Amazon</span> <span>July 2022 - Present</span></div>
                  Seattle, Washington<br/>
                  <span className="bold">Front End Software Engineer</span> <br/>
                  Front End Engineer on Live Events team for Prime Video.
                </div>
                <div className="section mt-3">
                  <div className="d-flex header"><span className="bold-company">Liferay</span> <span>Dec 2019 - Aug 2021</span></div>
                  Tokyo, Japan<br/>
                  <span className="bold">Consultant, Software Engineer</span> <br/>
                  Leading front end software engineer for Liferay APAC.
                </div>
                <div className="section mt-3">
                  <div className="d-flex header"><span className="bold-company">Marvel Entertainment</span> <span>Jan 2019 - May 2019</span></div>
                  New York City, New York<br/>
                  <span className="bold">Front End Engineering Intern</span> <br/>
                  NYC Marvel Entertainments only FE intern for Winter 2019.
                </div>
                <div className="section mt-3">
                  <div className="d-flex header"><span className="bold-company">IBM</span> <span>May 2018 - Aug 2018</span></div>
                  Southbury, CT<br/>
                  <span className="bold">Applications Developer Intern</span> <br/>
                  Applications developer intern under IBM CIO, stationed between Southbury, CT and Armonk, NY.
                </div>
                <div className="section mt-3">
                  <div className="d-flex header"><span className="bold-company">Japan Society</span> <span>May 2017 - Aug 2017</span></div>
                  New York City, New York<br/>
                  <span className="bold">Digital Media Intern</span> <br/>
                  Part of the Digital Media team to create content, illustrations and edit videos for non-profit events by Japan Society. 
                </div>
              </Fade>
          </div>
        </div>
        <div className='d-flex mt-5 bottom block mb-5'>
          <div className='col col-md-6 mb-3'>
            <h1>Skills</h1>
            <div className='line' />
            <Fade bottom>
              <div className="section mt-3 skills">
                <span className="bold">
                  <ul>
                    <li>ReactJS</li>  
                    <li>TypeScript</li>  
                    <li>Javascript</li>  
                    <li>Web Development and Design</li>  
                    <li>GraphQL</li>
                    <li>Node.js, npm</li>
                    <li>Git</li>
                    <li>GatsbyJS</li>
                    <li>Docker, DigitalOcean</li>
                  </ul>  
                </span> <br/>
              </div>
            </Fade>
          </div>
          <div className='col col-md-6'>
            <h1>Extras and Projects</h1>
            <div className='line' />
            <Fade bottom>
              <div className="section mt-3">
                <div className="d-flex header"><span className="bold-company">District Scholars</span></div>
                <span className="bold">Teaching underserved high school students modern web developement</span> and design through a 10-week guided course on <span className="bold">W3Schools HTML/SCSS, Figma, DOM Manipulation and JavaScript</span>.
              </div>
              <div className="section mt-3">
                <div className="d-flex header"><span className="bold-company">Midori Gal</span></div>
                Independent blogging platform built <span className="bold">Gatsby, GraphQL, ReactJS, Netlify, DigitalOcean, WordPress</span>
              </div>
              <div className="section mt-3">
                <div className="d-flex header"><span className="bold-company">Three Jewels</span></div>
                Technical and design support for wellness non-profit organization in Manhattan using <span className="bold">Squarespace, HTML/CSS, JS</span>
              </div>
              <div className="section mt-3">
                <div className="d-flex header"><span className="bold-company">Farming Relief in Osaka, Tokyo, Saitama</span></div>
                Farm relief support and community involvement for organizations such as <a href="https://www.oks-j.com/en/">Kawaguchi Farmer's Market</a>, <a href="https://www.seinousha.com/">Seinousha Saitama</a>.
              </div>
            </Fade>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Resume;